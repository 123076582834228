<script setup lang="ts">
import { CfgTypography, CfgTypographySizes } from '@cfgtech/ui'
import type { SbSectionStoryblok } from '~/types/storyblok'

const { blok } = defineProps<{ blok: SbSectionStoryblok }>()

const responsive = useResponsive()

const mobileBackgroundImage = computed(() => generateBackground(blok.mobileBackgoundImage?.filename))
const tabletBackgroundImage = computed(() => generateBackground(blok.tabletBackgoundImage?.filename))
const smallDesktopBackgroundImage = computed(() => generateBackground(blok.smallDesktopBackgoundImage?.filename))
const largeDesktopBackgroundImage = computed(() => generateBackground(blok.largeDesktopBackgoundImage?.filename))

// TODO: Find solution to use tailwind config instead
const colorsMap = {
  'brand': '#46B7B2',
  'brand-dark': '#337270',
  'primary': '#DA7D5B',
  'secondary': '#F9ECE6',
  'passive': '#F5F5F5',
} satisfies Record<keyof SbSectionStoryblok['backgroundColor'], 'string'>

const section = ref<HTMLElement | null>(null)
const container = ref<HTMLElement | null>(null)

const isHighlighted = ref(
  Boolean(
    blok.backgroundColor
    || blok.highlighted
    || blok.mobileBackgoundImage?.filename
    || blok.tabletBackgoundImage?.filename
    || blok.smallDesktopBackgoundImage?.filename
    || blok.largeDesktopBackgoundImage?.filename,
  ),
)

const renderedHeading = computed(() => blok.title?.content ? renderRichText(blok.title) : '')
const renderedDescription = computed(() => blok.description?.content ? renderRichText(blok.description) : '')

function generateBackground(image?: string) {
  const bgColor = blok.backgroundColor ? colorsMap[blok.backgroundColor] : ''

  if (!image) {
    return bgColor || 'none'
  }

  return `${bgColor} url(${image}) no-repeat center top / contain`
}
</script>

<template>
  <section
    v-editable="blok"
    class="sb-section flex flex-col justify-center"
    :class="{
      'sb-section__highlighted': isHighlighted || blok.highlighted,
      'rounded-[40px]': blok.rounded === 'full',
      'rounded-[24px] md:rounded-bl-[40px] md:rounded-br-[12px] md:rounded-tl-[12px] md:rounded-tr-[40px]': blok.rounded === 'diagonal',
      'overflow-hidden': !!blok.rounded,
    }"
  >
    <div
      ref="section"
      :class="{
        'bg-brand-light': blok.highlighted,
        'pb-0': !blok.highlighted && !isHighlighted,
        'sb-section-hightlight-target': !blok.backgroundTarget || blok.backgroundTarget === 'section',
      }"
    >
      <div
        :class="{
          'container': blok.backgroundTarget !== 'container',
          'px-1 lg:container': blok.backgroundTarget === 'container',
        }"
      >
        <div
          ref="container"
          :class="{
            'sb-section-hightlight-target rounded-xl px-5 lg:px-[8%]': (isHighlighted || blok.highlighted) && blok.backgroundTarget === 'container',
            'px-6 pb-10 pt-8 md:p-16 md:pt-10': blok.padding,
          }"
        >
          <div class="mx-auto flex w-full max-w-[53rem] flex-col text-balance md:text-center">
            <CfgTypography
              v-if="blok.tag"
              class="mb-2 text-grey-300"
              :size="CfgTypographySizes.h4"
              tag-name="p"
            >
              {{ blok.tag }}
            </CfgTypography>

            <TypographyStyledText>
              <CfgTypography
                :html="renderedHeading"
                :size="responsive.mobile ? CfgTypographySizes.h2 : CfgTypographySizes.h1"
              />
            </TypographyStyledText>

            <TypographyStyledText class="mx-auto mt-5 max-w-[41rem]">
              <CfgTypography
                :html="renderedDescription"
                :size="responsive.mobile ? CfgTypographySizes.md : CfgTypographySizes.subtitle"
              />
            </TypographyStyledText>
          </div>

          <div
            v-if="blok.body?.length"
            class="grid grid-cols-12"
            :class="{
              'md:mt-12': (renderedHeading || renderedDescription) && !blok.removeContentMargin,
              'mt-9': !(renderedHeading || renderedDescription) && !blok.removeContentMargin,
            }"
          >
            <div
              class="col-span-12"
              :class="{
                'xl:col-span-8 xl:col-start-3': blok.contentWidth === 's',
                'xl:col-span-10 xl:col-start-2': blok.contentWidth === 'm',
              }"
            >
              <StoryblokComponent
                v-for="bodyBlok in blok.body"
                :key="bodyBlok._uid"
                :blok="bodyBlok"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
.sb-section-hightlight-target {
  background: v-bind(mobileBackgroundImage);

  @screen md {
    background: v-bind(tabletBackgroundImage);
  }

  @screen lg {
    background: v-bind(smallDesktopBackgroundImage);
  }

  @screen 2xl {
    background: v-bind(largeDesktopBackgroundImage);
  }
}
</style>
